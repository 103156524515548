<script>
import { get } from 'vuex-pathify'
import { generateProductSchema } from './product-schema'
import productMixin from '@/v-shop/mixins/product-mixin'
import productSheetDesignMixin from '@/v-shop/productSheetDesignMixin'

export default {
	lang: 'shop',
	mixins: [productMixin('page'), productSheetDesignMixin],
	hooksKey: 'ProductPage',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-page',
	},
	metaInfo() {
		if (!this.product) return

		let title = [this.product.name]
		if (!this.product.hasUniqueVariant) {
			title.push(...this.selectedVariant.attrs.map((attr) => attr.v))
		}
		title = title.join(' - ')

		let description = this.product.info?.description || this.product.name
		let productSchema = generateProductSchema(
			this.$router,
			this.product,
			this.selectedVariant,
			this.initialReviews
		)
		let script = [{ json: productSchema, type: 'application/ld+json', vmid: 'ldjson-schema-product' }]
		let link = [{ rel: 'canonical', href: this.$router.resolve(this.$route).href }]
		return {
			title,
			meta: [{ vmid: 'd', name: 'description', content: description }],
			script,
			link,
		}
	},

	data() {
		return {
			product: null,
			relatedProducts: [],
			selectedVariantId: null,
			customization: {},
			validation: {},
			//collectionId: this.$srv('ProductAdditionalInfo.collectionId', null),
			// info: this.$srv('EnabledPaymentMethods'),
			initialReviews: [],
			discountTags: this.$srv('discountTags', []),
			imgIndex: 0,
			colSizes: {
				left: 7,
				right: 5,
			},
			showPromotions: false,
			hideStockAvailabilityMessage: this.$srv('shopOptions')?.hideStockAvailabilityMessage,
			userReviewsDialog: false,
			reviewsConfig: this.$srv('reviewsConfig'),
		}
	},
	computed: {
		sheetType() {
			return this.productSheetDesignConfig?.design || 'default'
		},
		routeId: get('route@params.id'),
		routeVariantId: get('route@query.variant'),
		selectedVariant() {
			return this.product.variants.find((x) => x.id == this.selectedVariantId)
		},
		breadcrumbs() {
			const categories = this.product.categories.map((category) => ({
				text: category.name,
				route: this.$shop.getShopRoute({ categories: category.familyUrlNames }),
			}))
			const first = {
				text: 'Todos los productos',
				route: this.$shop.getShopRoute(),
			}
			const last = {
				text: this.product.name,
			}
			return [first, ...categories, last]
		},
		attributes() {
			return this.product.attrs || []
		},
		marketplaceMode() {
			return process.env.VUE_APP_MARKETPLACE_MODE
		},
		showSeller() {
			let sellerId = this.$srv('sellerInfo.sellerId', [])
			return sellerId.some((id) => id == this.product.seller.id)
		},
	},
	watch: {
		$route() {
			this.onProductLoaded()
		},
		selectedVariantId(value, oldValue) {
			if (!oldValue || this.product.hasUniqueVariant) return
			if (value == this.$route.query.variant) return

			this.$router.push({
				params: { ...this.$route.params, scrollPosition: 'keep' },
				query: { ...this.$route.query, variant: value },
			})
		},
	},
	methods: {
		setValidation(value) {
			this.validation = value
		},
		async onProductLoaded() {
			if (!this.product) return

			const variant = this.product.variants.find((x) => x.id == this.selectedVariantId)
			if (variant.imageIdx) {
				this.imgIndex = variant.imageIdx - 1
			}

			if (this.product.urlName != this.$route.params.urlName) {
				await this.$router.replace({
					params: { ...this.$route.params, urlName: this.product.urlName },
				})
			}
			await this.autoAddToCart()

			this.$meta().refresh()
			this.$trackers.pageView()
			this.$eventer().trigger('product:view', {
				variant: this.selectedVariant,
				product: this.product,
			})
		},
		async autoAddToCart() {
			if (this.$route.query['add-to-cart'] != 1) return
			await this.$shop.addToCart({
				loading: false,
				productId: this.product.id,
				variantId: this.selectedVariant.id,
				showUpsells: false,
				showCart: false,
			})
			await this.$router.replace({
				query: { ...this.$route.query, 'add-to-cart': undefined },
			})
			this.$shop.showCart()
		},
		scrollToReviews() {
			this.$vuetify.goTo('#reviews-scroll-point', { duration: 500, offset: 0 })
		},
	},
	/*mobileReload(context, to, from) {
		return to.name != from.name || to.params.id != from.params.id
	},*/
	async resolveData(options, to, from, next) {
		let { ssr, apis, router, store, srv } = options
		if (!ssr && to.name == from.name && to.params.id == from.params.id) {
			return
		}

		let data
		if (ssr) {
			data = await ssr.loadViewData('product', to.params.id)
		} else {
			data = await apis.shopApi
				.get({
					loading: (v) => store.set('shop/routeLoading', v),
					url: `/catalog/product/${to.params.id}`,
				})
				.then((res) => res.data)
		}

		const { product, relatedProducts } = data
		if (!product) return { product: null }

		let selectedVariantId = to.query.variant
		let mainVariantId = product.variants.find((x) => x.forDisplay).id
		let redirect = false

		if (!product.hasUniqueVariant && !selectedVariantId) {
			to.query.variant = mainVariantId
			selectedVariantId = mainVariantId
			redirect = true
		} else if (product.hasUniqueVariant && selectedVariantId) {
			to.query.variant = undefined
			selectedVariantId = mainVariantId
			redirect = true
		} else if (selectedVariantId && !product.variants.some((x) => x.id == selectedVariantId)) {
			to.query.variant = mainVariantId
			selectedVariantId = mainVariantId
			redirect = true
		} else if (!selectedVariantId) {
			selectedVariantId = mainVariantId
		}

		if (redirect) {
			if (ssr) {
				ssr.responseSent = true
				ssr.res.redirect(301, router.resolve(to).href)
				return next(false)
			} else {
				next(to)
			}
		}

		const variant = product.variants.find((x) => x.id == selectedVariantId)
		const imgIndex = Math.max(0, variant.imageIdx - 1)

		let initialReviews
		if (ssr && srv('reviewsConfig.enabled')) {
			initialReviews = await ssr.loadViewData('productInitialReviews', {
				product,
				variant,
				qty: srv('reviewsConfig.productPageInitialReviewsQty', 5),
			})
		}

		if (ssr) ssr.shouldCache = true

		return {
			product,
			relatedProducts,
			selectedVariantId,
			imgIndex,
			initialReviews,
		}
	},
	mounted() {
		this.onProductLoaded()
	},
}
</script>

<template>
	<NotFoundPage v-if="!product" />

	<Container
		v-else
		class="product-page"
		:key="product.id"
		:style="sheetType == 'wide' && 'max-width: 1465px !important'"
	>
		<hook zone="top" />
		<Breadcrumbs class="py-5" :items="breadcrumbs" />
		<ProductPage-MainContainer :style="sheetType == 'wide' && 'max-width: 1465px !important'">
			<hook zone="top-inside" />
			<ProductSheetThreeColumns
				v-if="!$b.m && (sheetType == 'imageScroll' || sheetType == 'wide')"
				:sheetType="sheetType"
				@update:ratingAvg="product.ratingAvg = $event"
				@update:imgIndex="imgIndex = $event"
				@update:selectedVariantId="selectedVariantId = $event"
			/>
			<ProductSheetDefault
				v-else
				:sheetType="sheetType"
				@update:ratingAvg="product.ratingAvg = $event"
				@update:imgIndex="imgIndex = $event"
				@update:selectedVariantId="selectedVariantId = $event"
			/>

			<hook zone="bottom-inside" />
		</ProductPage-MainContainer>
		<hook zone="bottom" />

		<hook zone="related-products">
			<div class="product-page__related py-12" v-if="relatedProducts.length">
				<v-container class="pb-4 font-3 header font-weight-bold">
					{{ 'También te puede interesar' | lang }}
				</v-container>
				<v-container>
					<ProductsCarousel
						:products="relatedProducts"
						list-id="related-products"
						:dotContainerFullWidthMobile="relatedProducts.length >= 15"
						:arrowsBgHalfRounded="$b.mt"
						v-bind="$b.m && { slidesPerPage: 2, gutter: 5 }"
					/>
				</v-container>
			</div>
		</hook>

		<div
			id="reviews-scroll-point"
			:class="{ 'my-14': !relatedProducts.length, 'mb-14': relatedProducts.length }"
			v-if="
				reviewsConfig &&
				reviewsConfig.enabled &&
				(reviewsConfig.permissions == 'free' ? true : product.ratingCount > 0)
			"
		>
			<ProductPage-Reviews :product="product" :initial-reviews="initialReviews" />
		</div>
	</Container>
</template>

<style>
.shadow {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.divider,
.divider-small {
	margin-right: -12px;
	margin-left: -12px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
	.divider-small {
		margin-right: -36px;
		margin-left: -36px;
	}
}

.grey-border-top {
	border-top: 1px solid #eee;
}

.grey-border-right {
	border-right: 1px solid #eee;
}

.grey-border-bottom {
	border-bottom: 1px solid #eee;
}

.grey-border-left {
	border-left: 1px solid #eee;
}

.bordered {
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
}
</style>

