<script>
import { get } from 'vuex-pathify'
export default {
	metaInfo() {
		const { siteName, separator, metaDescription } = this.$srv('SitePageTitle', {})
		return {
			titleTemplate: (title) => {
				if (title) {
					return siteName ? `${title} ${separator} ${siteName}` : title
				} else {
					return siteName || ''
				}
			},
			meta: [{ vmid: 'd', name: 'description', content: metaDescription || '' }],
		}
	},
	provide() {
		return {
			getAppMessageDialog: () => this.$refs.appMessageDialog,
		}
	},
	computed: {
		appComponent: get('shop/appComponent'),
	},
	eventsListeners: {
		'app-message'(options) {
			this.$refs.appMessageDialog.open(options)
		},
	},
	mounted() {
		this.$shopApi.defaults({
			onMessage: ({ message, success }) => {
				if (!message.title && !message.text && !message.description) return
				this.$refs.appMessageDialog.open({
					type: message.type || (success ? 'success' : 'error'),
					title: message.title,
					text: message.text,
					description: message.description,
					//code: message.code,
				})
			},
			onConfirm: ({ confirmed, canceled, options }) => {
				this.$refs.appMessageDialog.open({
					title: options.confirm.title,
					text: options.confirm.text,
					htmlText: options.confirm.htmlText,
					accept: options.confirm.accept,
					cancel: options.confirm.cancel,
					onAccept: confirmed,
					onCancel: canceled,
				})
			},
		})
		this.$nextTick(() => {
			setTimeout(() => {
				for (let x in window.__USER_DATA__) {
					this.$store.set(x, window.__USER_DATA__[x])
				}
			}, 1500)
		})
	},
	cssVars: {
		selector: '.shop-app',
	},
}
</script>

<template>
	<v-app class="shop-app base">
		<AppRouteLoadingBar />
		<component :is="appComponent" id="app" />
		<ClientSideOnly>
			<AppMessageDialog ref="appMessageDialog" />
		</ClientSideOnly>
		<CookiesDisclaimer />
	</v-app>
</template>

